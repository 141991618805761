
import { Component, Vue } from 'vue-property-decorator';
import BaseContact from '@thxnetwork/public/components/BaseContact.vue';
import BaseCardUseCase from '@thxnetwork/public/components/BaseCardUseCase.vue';
import { USE_CASES_TAGS, TWITTER_TAGS, ALT_TEXT, LINKS, TITLES } from '@thxnetwork/public/utils/constants';
import { content } from './UseCaseDetail.vue';

@Component({
    metaInfo: {
        title: USE_CASES_TAGS.TITLE,
        meta: [
            { name: 'title', content: USE_CASES_TAGS.TITLE },
            { name: 'description', content: USE_CASES_TAGS.DESCRIPTION },
            { name: 'keywords', content: USE_CASES_TAGS.KEYWORDS },
            { name: 'twitter:card', content: TWITTER_TAGS.TWITTER_CARD },
            { name: 'twitter:site', content: TWITTER_TAGS.TWITTER_SITE },
            { name: 'twitter:creator', content: TWITTER_TAGS.TWITTER_CREATOR },
            { name: 'twitter:title', content: USE_CASES_TAGS.TITLE },
            { name: 'twitter:description', content: USE_CASES_TAGS.DESCRIPTION },
            { name: 'twitter:image:alt', content: USE_CASES_TAGS.TITLE },
            { property: 'og:title', content: USE_CASES_TAGS.TITLE },
            { property: 'og:description', content: USE_CASES_TAGS.DESCRIPTION },
            { property: 'og:type', content: USE_CASES_TAGS.OG_URL },
            { property: 'og:site_name', content: USE_CASES_TAGS.OG_SITE_NAME },
            { property: 'og:url', content: USE_CASES_TAGS.OG_URL },
        ],
        link: [{ rel: 'canonical', href: LINKS.USE_CASES }],
    },
    components: {
        BaseCardUseCase,
        BaseContact,
    },
})
export default class Home extends Vue {
    content = content;
    ALT_TEXT = ALT_TEXT;
    TITLES = TITLES;
    _tabIndex = 0;
    filters = [
        {
            label: 'All',
            hash: '#all',
            icon: 'fas fa-th-large',
        },
        {
            label: 'Gaming',
            hash: '#gaming',
            icon: 'fas fa-gamepad-alt',
        },
        {
            label: 'Defi',
            hash: '#defi',
            icon: 'fas fa-chart-network',
        },
        {
            label: 'Digital Twin',
            hash: '#digital-twin',
            icon: 'fas fa-qrcode',
        },
    ];

    get useCases() {
        return [
            'gala',
            'titanborn',
            'carbify',
            'tryhards',
            'royal-dutch-mint',
            'forest-knight',
            'apebond',
            'blind-boxes',
            '2tokens',
        ]
            .map((key) => ({
                key,
                ...content[key],
            }))
            .filter((c) => c.tag.map((t) => `#${t}`).includes(this.$route.hash));
    }

    mounted() {
        const hashes = this.filters.map((f) => f.hash);
        if (!hashes.includes(this.$route.hash)) {
            this.$router.push('/use-cases#all');
        }
    }
}
